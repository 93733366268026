import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Site’s built with the Carbon Gatsby theme starter come ready to go with some helpful npm scripts. You can run these commands by typing `}<inlineCode parentName="p">{`yarn [command]`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm run [command]`}</inlineCode></p>
    </PageDescription>
    <h2>{`Clean`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`clean`}</strong>{` - this is used to clean gatsby’s cache and public directory. It should be used sparingly, only when you’re experiencing weird errors or caching issues.`}</li>
    </ul>
    <h2>{`Develop`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`develop`}</strong>{` - this is your primary for starting up your gatsby site for development`}</li>
      <li parentName="ul"><strong parentName="li">{`develop:clean`}</strong>{` - this is provided as a convenience; it first runs `}<inlineCode parentName="li">{`clean`}</inlineCode>{` then `}<inlineCode parentName="li">{`develop`}</inlineCode></li>
    </ul>
    <h2>{`Build`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`build`}</strong>{` - this is what you’ll use to bundle your site. Gatsby will minimize your images and create a static, blazing fast site in your `}<inlineCode parentName="li">{`public`}</inlineCode>{` directory`}</li>
      <li parentName="ul"><strong parentName="li">{`serve`}</strong>{` - this can be run to serve the bundle locally after running `}<inlineCode parentName="li">{`build`}</inlineCode>{`. It can be used to debug locally if any issues are encountered durring build.`}</li>
      <li parentName="ul"><strong parentName="li">{`build:clean`}</strong>{` - this is a provided as a convenience, it first runs `}<inlineCode parentName="li">{`clean`}</inlineCode>{` then `}<inlineCode parentName="li">{`build`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`build:prefix`}</strong>{` - this will append all of your links with a `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.org/docs/path-prefix/"
        }}><inlineCode parentName="a">{`pathPrefix`}</inlineCode></a>{` specified in your `}<inlineCode parentName="li">{`gatsby-config.js`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`serve:prefix`}</strong>{` - this will allow you to serve the file locally using a prefixed site built with `}<inlineCode parentName="li">{`build:prefix`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`test:prefix`}</strong>{` - this is provided as a convenience; it first runs `}<inlineCode parentName="li">{`build:prefix`}</inlineCode>{` then `}<inlineCode parentName="li">{`test:prefix`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`build:analyze`}</strong>{` - this will run build while also running `}<a parentName="li" {...{
          "href": "https://github.com/webpack-contrib/webpack-bundle-analyzer"
        }}>{`webpack-bundle-analyzer`}</a>{` to help debug bundle size issues
`}<span parentName="li" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.05555555555554%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAafFvEpwwbP/xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISIf/aAAgBAQABBQLMjWXV90Ukxwj/xAAYEQACAwAAAAAAAAAAAAAAAAAAAhITUf/aAAgBAwEBPwGWlqn/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8BTpD/xAAVEAEBAAAAAAAAAAAAAAAAAAAgMv/aAAgBAQAGPwKl/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERMSFRsf/aAAgBAQABPyG7ngVHohFYma05VobeH//aAAwDAQACAAMAAAAQ78//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFh/9oACAEDAQE/EDLObf/EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAECAQE/EGh//8QAGRABAQEBAQEAAAAAAAAAAAAAAREAMUEh/9oACAEBAAE/EGkcPK0rqdOrxp0uONd9DKoK9mWr97//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "webpack bundle analysis screenshot",
            "title": "webpack bundle analysis screenshot",
            "src": "/static/82ef289505430d0af3cde976d928aace/f6133/analyzer.jpg",
            "srcSet": ["/static/82ef289505430d0af3cde976d928aace/69549/analyzer.jpg 288w", "/static/82ef289505430d0af3cde976d928aace/473e3/analyzer.jpg 576w", "/static/82ef289505430d0af3cde976d928aace/f6133/analyzer.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      